import { Route, Routes } from "react-router-dom";
import Overview from "./views/Overview";
import { ProtectedRoute } from "components/ProtectedRoute";
import { Provider } from "react-redux";
import store from "state/store";
import Mod from "views/Mod";
import ModQueue from "views/ModQueue";
import Moderated from "views/Moderated";
import Queued from "views/Queued";
import Posted from "views/Posted";
import Profile from "views/Profile";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <Routes>
          <Route path="/" element={<ProtectedRoute component={Mod} />}>
            <Route index element={<ProtectedRoute component={Overview} />} />
            <Route
              path="overview"
              element={<ProtectedRoute component={Overview} />}
            />
            <Route
              path="modqueue"
              element={<ProtectedRoute component={ModQueue} />}
            />
            <Route
              path="moderated"
              element={<ProtectedRoute component={Moderated} />}
            />
            <Route
              path="queued"
              element={<ProtectedRoute component={Queued} />}
            />
            <Route
              path="posted"
              element={<ProtectedRoute component={Posted} />}
            />
            <Route
              path="profile"
              element={<ProtectedRoute component={Profile} />}
            />
          </Route>
        </Routes>
      </Provider>
    </div>
  );
}

export default App;
