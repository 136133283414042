import axios from "axios";

let server = process.env.REACT_APP_API_BASE;

var rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });

let units = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: (24 * 60 * 60 * 1000 * 365) / 12,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000,
};

export const GET = (url) => {
  return axios.get(`${server}/${url}`);
};

export const POST = (url, data) => {
  return axios(`${server}/${url}`, {
    method: "POST",
    data,
  });
};

export const PATCH = (url, data) => {
  return axios(`${server}/${url}`, {
    method: "PATCH",
    data,
  });
};

export const DELETE = (url) => {
  return axios(`${server}/${url}`, {
    method: "DELETE",
  });
};

async function getTrinders() {
  return GET("queue");
}

async function getTrindersByType(type) {
  return POST("queue", { type });
}

async function submitTrinder(trinder) {
  return POST("queue/submit", { trinder });
}

async function rejectTrinder(guid, username) {
  return PATCH("queue/reject", {
    guid,
    username,
  });
}

async function approveTrinder(guid, username) {
  return PATCH("queue/approve", {
    guid,
    username,
  });
}

// async function deleteTrinder(guid) {
//     return DELETE(`queue/guid/${guid}`)
// }

function submitEditedTrinder(guid, trinder) {
  return PATCH(`queue/guid/${guid}`, { trinder });
}

function humanTime(string) {
  return Intl.DateTimeFormat("en-gb", {
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: "GMT",
  }).format(new Date(string));
}

async function getQueueStats() {
  return GET("queue/stats");
}

let getRelativeTime = (d1, d2 = new Date()) => {
  let elapsed = d1 - d2;

  // "Math.abs" accounts for both "past" & "future" scenarios
  for (let u in units)
    if (Math.abs(elapsed) > units[u] || u === "second")
      return rtf.format(Math.round(elapsed / units[u]), u);
};

function setServer(s) {
  server = s;
}

export {
  setServer,
  getTrinders,
  submitTrinder,
  submitEditedTrinder,
  humanTime,
  rejectTrinder,
  getTrindersByType,
  approveTrinder,
  getQueueStats,
  getRelativeTime,
};
