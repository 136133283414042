import { createSlice } from "@reduxjs/toolkit";

export const modViewSlice = createSlice({
  name: "modViewSlice",
  initialState: {
    trinders: [],
    stats: {
      totalUnmoderated: 0,
      totalTrinders: 0,
      totalQueued: 0,
      totalPosted: 0,
    },
  },
  reducers: {
    setTrinders: (state, action) => {
      state.trinders = action.payload;
    },
    setStats: (state, action) => {
      state.stats = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTrinders, setStats } = modViewSlice.actions;

export default modViewSlice.reducer;
