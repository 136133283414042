import { Outlet } from "react-router-dom";
import "style/components/dashboard/dashboard.css";

import DSidebar from "components/dashboard/DSidebar";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";

export default function Mod() {
  const { user, logout } = useAuth0();
  let [active, setActive] = useState(0);

  return (
    <div className="grid-container">
      <div className="menu-icon">
        <strong> &#9776;</strong>
      </div>
      <header className="header">
        <div className="header_search">
          You are currently logged in as {user.name}
        </div>
        <div
          className="header_avatar"
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          Logout
        </div>
      </header>
      <DSidebar active={active} setActive={setActive} />
      <Outlet />
      <footer className="footer">
        <div className="footer_copyright">
          &copy; 2022 &mdash; Running "Campus Confessions" v1.0.0
        </div>
        <div className="footer_byline">
          Run with &hearts; by the Trinder Admin
        </div>
      </footer>
    </div>
  );
}
