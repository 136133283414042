import "./style/DObject.css";

export default function DObject({ title, object }) {
  let objectElems = Object.keys(object).map((key) => {
    return (
      <div key={key} className="dobject-item">
        <div className="dobject-key">{key}</div>
        <div className="dobject-value">{object[key]}</div>
      </div>
    );
  });

  return (
    <div className="card">
      <h1> {title} </h1>
      <div className="dobject">{objectElems}</div>
    </div>
  );
}
