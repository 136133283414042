import { withAuthenticationRequired } from "@auth0/auth0-react";

export const ProtectedRoute = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Loader />,
  });

  return <Component />;
};

function Loader() {
  return <h1>Loading...</h1>;
}
