import DCard from "components/dashboard/DCard";
import { useEffect, useState } from "react";
import { getQueueStats, getRelativeTime, getTrinders } from "state/api";
import { useDispatch, useSelector } from "react-redux";
import { setStats, setTrinders } from "state/slices/modViewSlice";
import DTable from "components/dashboard/DTable";
import DObject from "components/dashboard/DObject";

export default function Overview() {
  let { stats } = useSelector((state) => state.modViewSlice);
  let dispatch = useDispatch();

  let latestTrindersColumns = ["Time", "Content"];
  let [latestTrindersData, setLatestTrinderData] = useState([]);

  useEffect(() => {
    getQueueStats().then((resp) => {
      dispatch(setStats(resp.data));
    });

    getTrinders().then((resp) => {
      dispatch(setTrinders(resp.data));

      let currentTime = new Date();
      let data = [];
      resp.data.forEach((trinder) => {
        data.push({
          Time: getRelativeTime(
            new Date(trinder["time-submitted"]),
            currentTime
          ),
          Content: trinder.body,
        });
      });

      setLatestTrinderData(data.reverse());
    });
  }, [dispatch]);

  return (
    <main className="main">
      <div className="main_overview">
        <DCard k={"Unmoderated"} v={stats.totalUnmoderated} />
        <DCard k={"Queued"} v={stats.totalQueued} />
        <DCard k={"Posted"} v={stats.totalPosted} />
        <DCard k={"Total"} v={stats.totalTrinders} />
      </div>

      <div className="main_cards">
        <DTable
          title={"Recently Submitted"}
          columns={latestTrindersColumns}
          data={latestTrindersData}
        />
        <DObject title="Your Stats" object={stats} />
      </div>
    </main>
  );
}
