import {
  submitEditedTrinder,
  rejectTrinder,
  approveTrinder,
  getRelativeTime,
} from "state/api";
import { useState } from "react";

export default function DTrinderEntry(props) {
  let [state, setState] = useState({
    guid: props.trinder.guid,
    body: props.trinder.body,
    timeSubmitted: props.trinder["time-submitted"],
    isEdit: false,
    username: props.username,
    approved: false,
    rejected: false,
  });

  function onType(event) {
    const { name, value, type, checked } = event.target;
    setState((prev) => {
      return {
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  }

  function onApprove() {
    approveTrinder(state.guid, state.body);
    setState((prev) => ({
      ...prev,
      approved: true,
    }));
  }

  function onEdit() {
    setState((prev) => ({ ...prev, isEdit: false }));
    submitEditedTrinder(state.guid, state.body);
  }

  function onReject() {
    rejectTrinder(state.guid, state.username);
    setState((prev) => ({
      ...prev,
      rejected: true,
    }));
  }

  if (state.approved || state.rejected) {
    return null;
  }

  return (
    <tr>
      <td>{getRelativeTime(new Date(state.timeSubmitted), new Date())}</td>

      <td>
        {!state.isEdit ? (
          state.body
        ) : (
          <div>
            <input
              className="trinder-editor"
              name="body"
              value={state.body}
              onChange={onType}
            />
            <i
              className="fa-solid fa-check fa-lg clickable"
              onClick={onEdit}
            ></i>
          </div>
        )}
      </td>

      <td>
        {!state.isEdit && (
          <i
            className="fa-solid fa-pen fa-lg clickable"
            onClick={() => setState((prev) => ({ ...prev, isEdit: true }))}
          ></i>
        )}
        &nbsp;
        <i
          className="fa-solid fa-thumbs-up fa-lg clickable"
          onClick={onApprove}
        ></i>
        &nbsp;
        <i className="fa-solid fa-x fa-lg clickable" onClick={onReject}></i>
        {/* &nbsp;
        <i className="fa-solid fa-flag fa-lg clickable"></i> &nbsp;
        <i className="fa-solid fa-fire fa-lg clickable"></i> &nbsp; */}
      </td>
    </tr>
  );
}
