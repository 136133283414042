import DTable from "components/dashboard/DTable";
import { useEffect, useState } from "react";
import { getRelativeTime, getTrindersByType } from "state/api";

export default function Moderated() {
  let [data, setData] = useState([]);

  useEffect(() => {
    let currentTime = new Date();
    getTrindersByType("pending").then((resp) => {
      let rawData = [];
      resp.data.forEach((trinder) => {
        rawData.push({
          Time: getRelativeTime(
            new Date(trinder["time-submitted"]),
            currentTime
          ),
          Content: trinder.body,
        });
      });
      setData(rawData.reverse());
    });
  }, []);

  return (
    <div className="card">
      <h1>Already Moderated</h1>
      <p>
        This list contains Trinders which you have already moderated, but are
        waiting for a second moderator.
      </p>
      <DTable
        title="Pending Trinders"
        columns={["Time", "Content"]}
        data={data}
      />
    </div>
  );
}
