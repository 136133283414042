import "./style/DTable.css";

export default function DTable({ title, columns, data }) {
  let columnsElems = (
    <tr>
      {columns.map((column) => (
        <th key={Math.random()}>{column}</th>
      ))}
    </tr>
  );

  let dataElems = data.map((elem) => (
    <tr key={Math.random()}>
      {columns.map((column) => (
        <td key={Math.random()}>{elem[column]}</td>
      ))}
    </tr>
  ));

  return (
    <div className="card">
      <h1> {title} </h1>
      <div className="dtable-scroll">
        <table className="dtable">
          <thead>{columnsElems}</thead>
          <tbody>{dataElems}</tbody>
        </table>
      </div>
    </div>
  );
}
