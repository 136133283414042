import { useEffect, useState } from "react";
import { getTrindersByType } from "state/api";

import DTrinderEntry from "components/dashboard/DTrinderEntry";
import { useAuth0 } from "@auth0/auth0-react";

export default function ModQueue() {
  let { user } = useAuth0();
  let [pendingTrinders, setPendingTrinders] = useState([]);

  useEffect(() => {
    getTrindersByType("pending").then((resp) => {
      let data = resp.data;
      // Filters out trinders this user has already acted upon
      data = data.filter(
        (entry) =>
          !(entry.rejectors && entry.rejectors.includes(user.email)) ||
          !(entry.approvers && entry.approvers.includes(user.email))
      );

      setPendingTrinders(data);
    });
  }, []);

  let trinderEntries = pendingTrinders.map((trinder) => {
    return (
      <DTrinderEntry
        key={trinder.guid}
        trinder={trinder}
        username={user.email}
      />
    );
  });

  return (
    <div className="mod-queue">
      <div className="card">
        <h1>Moderation queue</h1>
        <p>
          This screen is for you, moderators, to explicitly reject or approve
          trinders which have been submitted. Make sure trinders you approve
          follow the rules of trinder, and to use the edit feature to remove any
          personally identifiable information such as full names. Your access to
          this system should be kept confidential at all times. Trinders require
          two separate rejections or approvals to be queued by the twitter and
          instagram bots. Trinders you have already moderated will no longer
          appear in your own queue.
        </p>
        <table>
          <thead>
            <tr>
              <th>Time</th>
              <th>Content</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{trinderEntries}</tbody>
        </table>
      </div>
    </div>
  );
}
