import "./style/DCard.scss";

export default function DCard({ k, v }) {
  return (
    <div className="overview_card">
      <div className="overview_card-info strong">{k}</div>
      <div className="overview_card-icon">{v}</div>
    </div>
  );
}
