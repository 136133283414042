import { Link, useLocation } from "react-router-dom";

export default function DSidebar({ active, setActive }) {
  return (
    <aside className="aside">
      <div className="aside_close-icon">
        <strong>&times;</strong>
      </div>

      <div className="aside_header">
        <h1> Trinder </h1>
        <p> Moderation Panel </p>
        <span className="version">v1.0.0</span>
      </div>
      <ul className="aside_list">
        <Link to="/overview" onClick={() => setActive(0)}>
          <li className={"aside_list-item" + (active === 0 ? " active" : "")}>
            <i className="fa-solid fa-chart-line" />
            Overview
          </li>
        </Link>

        <Link to="/modqueue" onClick={() => setActive(1)}>
          <li className={"aside_list-item" + (active === 1 ? " active" : "")}>
            <i className="fa-solid fa-clipboard"></i>
            Moderation Queue
          </li>
        </Link>

        <Link to="/moderated" onClick={() => setActive(2)}>
          <li className={"aside_list-item" + (active === 2 ? " active" : "")}>
            <i className="fa-solid fa-clipboard-check" />
            Already Moderated
          </li>
        </Link>

        <Link to="/queued" onClick={() => setActive(3)}>
          <li className={"aside_list-item" + (active === 3 ? " active" : "")}>
            <i className="fa-solid fa-link"></i>
            Queued
          </li>
        </Link>

        <Link to="/posted" onClick={() => setActive(4)}>
          <li className={"aside_list-item" + (active === 4 ? " active" : "")}>
            <i className="fa-solid fa-flag-checkered"></i>
            Recently Posted
          </li>
        </Link>

        <Link to="/profile" onClick={() => setActive(5)}>
          <li className={"aside_list-item" + (active === 5 ? " active" : "")}>
            <i className="fa-solid fa-address-card"></i>
            Your Profile
          </li>
        </Link>
      </ul>
    </aside>
  );
}
